<template>
<!-- 此页勿删！ -->
  <div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: 'MaterUpload',
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  destroyed() {
  },
  created() {
  },
  mounted () {
  $(".before_upload").show()
    $(".before_upload").css('height', '70vh')
    window.onresize = ()=>{
        $(".before_upload").css('height', '70vh')
      }
    this.$forceUpdate()
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
</style>
<style lang="scss">

</style>


